/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: "Marianne";
    src: local("Marianne"), url("./assets/font/Marianne-Light.otf") format("truetype");
}

html, body { height: 100%; width: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; 
}
.retour-chariot{
    display: block;
  }

      footer {
          background-color: white;
      }
      app-root {
        min-width: 100%;
      }